<template>
  <div class="w-auto flex mx-11 pt-5">
    <img src="@/assets/images/upload-icon.svg" alt="" />
    <label class="custom-file-upload">
      <input
        ref="file"
        type="file"
        @change="onFileSelected"
        accept="application/pdf"
        id="file"
        class="d-none"
      />
      <span class="upload-text ml-2">Upload a File (PDF)</span>
    </label>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      selectedFile: "",
      fileToDelete: "",
      fileName: "",
      pdfUrl: "",
    };
  },
  props: ["section"],
  methods: {
    onFileSelected(event) {
      if (event.target.files[0] == undefined) {
        return;
      }
      this.selectedFile = event.target.files[0];
      this.onUpload();
    },
    onUpload() {
      const loading = this.$vs.loading({
        color: "#0276FF",
      });
      //variable auxiliar
      this.fileToDelete = this.section.file_name;
      this.fileName = this.selectedFile.name;
      const deleteRef = firebase
        .storage()
        .ref(`/home-sections/${this.section.type_banner}/${this.fileToDelete}`);

      const storageRef = firebase
        .storage()
        .ref(
          `/home-sections/${this.section.type_banner}/${this.selectedFile.name}`
        );
      const task = storageRef.put(this.selectedFile);

      task.on(
        "state_changed",
        (snapshot) => {
          let percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.uploadValue = percentage;
        },
        (error) => console.log(error.message),
        () => {
          this.uploadValue = 100;
          task.snapshot.ref.getDownloadURL().then((url) => {
            //console.log("URL", url);
            this.pdfUrl = url;
            loading.close();
            this.section.pdf_url = this.pdfUrl;
            this.section.file_name = this.fileName;

            this.$emit("editarSection", this.section);

            if (this.fileToDelete != this.fileName) {
              deleteRef
                .delete(this.fileToDelete)
                .then(() => {
                  console.log("Archivo Borrado");
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      );
    },
  },
};
</script>

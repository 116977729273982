<template>
  <div>
    <div class="mb-5 uploader-text">Uploader |</div>
    <!-- Intro Banner Uploader --->
    <div class="flex" v-if="intro_section">
      <Square page="home-sections" item="intro_section"></Square>
      <div class="bg-white rectangle">
        <div class="w-4/5 pt-5 mx-8">
          <span class="title-box">DESCRIPTION INTRO BANNER</span>
        </div>
        <div class="flex mx-8 pt-9">
          <!-- <span>Title</span> -->
          <vs-input
            label="Title"
            v-model="intro_section.title"
            class="input-bluexpress-component"
            placeholder="Junto a ti cuidando tus sueños"
          />
          <vs-input
            label="Description"
            v-model="intro_section.subtitle"
            class="input-bluexpress-component"
            placeholder="Solicite nuestra lista de precios más reciente"
          />
        </div>
        <div class="flex pt-12 mx-8">
          <vs-input
            label="Link"
            v-model="intro_section.pdf_url"
            class="input-bluexpress-component"
            placeholder="Http://bluexpress.com/pdf?"
            disabled
          />
          <vs-input
            label="CTA (Button)"
            v-model="intro_section.button_name"
            class="input-bluexpress-component"
            placeholder="Solicitar >"
          />
        </div>
        <!-- UPLOAD DE PDF -->
        <Pdf :section="intro_section" @editarSection="editSection"></Pdf>
        <!-- FIN DE UPLOAD DE PDF -->
        <div class="flex items-center justify-end w-auto pt-5 mx-8">
          <vs-button
            :disabled="!introValidation"
            @click="editSection(intro_section)"
          >
            Guardar
          </vs-button>
        </div>
      </div>
    </div>
    <!-- End Intro Banner Uploader --->

    <!-- Intro SECTION-1 Uploader --->
    <div class="flex" v-if="section_1">
      <Square page="home-sections" item="section_1"></Square>
      <div class="bg-white rectangle">
        <div class="w-4/5 pt-5 mx-8">
          <span class="title-box"
            >DESCRIPTION SECTION 1 - CATALOGO HERRAJES</span
          >
        </div>
        <div class="flex mx-8 pt-9">
          <!-- <span>Title</span> -->
          <vs-input
            label="Title"
            v-model="section_1.title"
            class="input-bluexpress-component"
            placeholder="Junto a ti cuidando tus sueños"
          />
          <vs-input
            label="Description"
            v-model="section_1.subtitle"
            class="input-bluexpress-component"
            placeholder="Solicite nuestra lista de precios más reciente"
          />
        </div>
        <div class="flex pt-12 mx-8">
          <vs-input
            label="Link"
            v-model="section_1.pdf_url"
            class="input-bluexpress-component"
            placeholder="Http://bluexpress.com/pdf?"
            disabled
          />
          <vs-input
            label="CTA (Button)"
            v-model="section_1.button_name"
            class="input-bluexpress-component"
            placeholder="Solicitar >"
          />
        </div>
        <!-- UPLOAD DE PDF -->
        <Pdf :section="section_1" @editarSection="editSection"></Pdf>
        <!-- FIN DE UPLOAD DE PDF -->
        <div class="flex items-center justify-end w-auto pt-5 mx-8">
          <vs-button
            :disabled="!section1Validation"
            @click="editSection(section_1)"
          >
            Guardar
          </vs-button>
        </div>
      </div>
    </div>
    <!-- End SECTION-1 Uploader --->

    <!-- Intro SECTION-2 Uploader --->
    <div class="flex" v-if="section_2">
      <Square page="home-sections" item="section_2"></Square>
      <div class="bg-white rectangle">
        <div class="w-4/5 pt-5 mx-8">
          <span class="title-box"
            >DESCRIPTION SECTION 2 - CATALOGO HERRAJES</span
          >
        </div>
        <div class="flex mx-8 pt-9">
          <!-- <span>Title</span> -->
          <vs-input
            label="Title"
            v-model="section_2.title"
            class="input-bluexpress-component"
            placeholder="Junto a ti cuidando tus sueños"
          />
          <vs-input
            label="Description"
            v-model="section_2.subtitle"
            class="input-bluexpress-component"
            placeholder="Solicite nuestra lista de precios más reciente"
          />
        </div>
        <div class="flex pt-12 mx-8">
          <vs-input
            label="Link"
            v-model="section_2.pdf_url"
            class="input-bluexpress-component"
            placeholder="Http://bluexpress.com/pdf?"
            disabled
          />
          <vs-input
            label="CTA (Button)"
            v-model="section_2.button_name"
            class="input-bluexpress-component"
            placeholder="Solicitar >"
          />
        </div>
        <!-- UPLOAD DE PDF -->
        <Pdf :section="section_2" @editarSection="editSection"></Pdf>
        <!-- FIN DE UPLOAD DE PDF -->
        <div class="flex items-center justify-end w-auto pt-5 mx-8">
          <vs-button
            :disabled="!section2Validation"
            @click="editSection(section_2)"
          >
            Guardar
          </vs-button>
        </div>
      </div>
    </div>
    <!-- End SECTION-2 Uploader --->

    <!-- SECTION-3 Uploader --->

    <div class="flex" v-if="section_3">
      <Square page="home-sections" item="section_3"></Square>
      <div class="bg-white rectangle">
        <div class="w-4/5 pt-5 mx-8">
          <span class="title-box"
            >DESCRIPTION SECTION 3 - CATALOGO HERRAJES</span
          >
        </div>
        <div class="flex mx-8 pt-9">
          <!-- <span>Title</span> -->
          <vs-input
            label="Title"
            v-model="section_3.title"
            class="input-bluexpress-component"
            placeholder="Junto a ti cuidando tus sueños"
          />
          <vs-input
            label="Description"
            v-model="section_3.subtitle"
            class="input-bluexpress-component"
            placeholder="Solicite nuestra lista de precios más reciente"
          />
        </div>
        <div class="flex pt-12 mx-8">
          <vs-input
            label="Link"
            v-model="section_3.pdf_url"
            class="input-bluexpress-component"
            placeholder="Http://bluexpress.com/pdf?"
            disabled
          />
          <vs-input
            label="CTA (Button)"
            v-model="section_3.button_name"
            class="input-bluexpress-component"
            placeholder="Solicitar >"
          />
        </div>
        <!-- UPLOAD DE PDF -->
        <Pdf :section="section_3" @editarSection="editSection"></Pdf>
        <!-- FIN DE UPLOAD DE PDF -->
        <div class="flex items-center justify-end w-auto pt-5 mx-8">
          <vs-button
            :disabled="!section3Validation"
            @click="editSection(section_3)"
          >
            Guardar
          </vs-button>
        </div>
      </div>
    </div>

    <!-- End SECTION-3 Uploader --->

    <!-- DUO-1 Uploader --->

    <div class="flex" v-if="duo_1">
      <Square page="home-sections" item="duo_1"></Square>
      <div class="bg-white rectangle">
        <div class="w-4/5 pt-5 mx-8">
          <span class="title-box">DESCRIPTION DUO 1 - CATALOGO HERRAJES</span>
        </div>
        <div class="flex mx-8 pt-9">
          <!-- <span>Title</span> -->
          <vs-input
            label="Title"
            v-model="duo_1.title"
            class="input-bluexpress-component"
            placeholder="Junto a ti cuidando tus sueños"
          />
          <vs-input
            label="Description"
            v-model="duo_1.subtitle"
            class="input-bluexpress-component"
            placeholder="Solicite nuestra lista de precios más reciente"
          />
        </div>
        <div class="flex pt-12 mx-8">
          <vs-input
            label="Link"
            v-model="duo_1.pdf_url"
            class="input-bluexpress-component"
            placeholder="Http://bluexpress.com/pdf?"
            disabled
          />
          <vs-input
            label="CTA (Button)"
            v-model="duo_1.button_name"
            class="input-bluexpress-component"
            placeholder="Solicitar >"
          />
        </div>
        <!-- UPLOAD DE PDF -->
        <Pdf :section="duo_1" @editarSection="editSection"></Pdf>
        <!-- FIN DE UPLOAD DE PDF -->
        <div class="flex items-center justify-end w-auto pt-5 mx-8">
          <vs-button :disabled="!duo1Validation" @click="editSection(duo_1)">
            Guardar
          </vs-button>
        </div>
      </div>
    </div>
    <!-- END DUO-1 Uploader --->

    <!-- RRSS Uploader --->

    <div class="flex" v-if="section_rrss">
      <Square page="home-sections" item="section_rrss"></Square>
      <div class="bg-white rectangle">
        <div class="w-4/5 pt-5 mx-8">
          <span class="title-box">DESCRIPTION RRSS - REDES SOCIALES</span>
        </div>
        <div class="flex mx-8 pt-9">
          <!-- <span>Title</span> -->
          <vs-input
            label="Title"
            v-model="section_rrss.title"
            class="input-bluexpress-component"
            placeholder="Siguenos en nuestras redes"
          />
          <vs-input
            label="Description"
            v-model="section_rrss.subtitle"
            class="input-bluexpress-component"
            placeholder="@Bluexpress.ve"
          />
        </div>
        <div class="flex mx-8 pt-9 center-flex">
          <!-- <span>Title</span> -->
          <div class="flex">
            <vs-input
              label="Instagram Link"
              v-model="section_rrss.instagram_url"
              class="input-bluexpress-component"
              placeholder="Http://bluexpress.com/pdf?"
            />
            <vs-input
              label="Facebook Link"
              v-model="section_rrss.facebook_url"
              class="input-bluexpress-component"
              placeholder="Solicitar >"
            />
            <vs-input
              label="Whatsapp Link"
              v-model="section_rrss.whatsapp_url"
              class="input-bluexpress-component"
              placeholder="Solicitar >"
            />
          </div>
        </div>
        <div class="flex items-center justify-end w-auto pt-5 mx-8">
          <vs-button
            :disabled="!rrssValidation"
            @click="editSection(section_rrss)"
          >
            Guardar
          </vs-button>
        </div>
      </div>
    </div>

    <!-- END RRSS Uploader --->

    <!-- Business Uploader --->

    <div class="flex" v-if="section_business">
      <Square page="home-sections" item="section_business"></Square>
      <div class="bg-white rectangle">
        <div class="w-4/5 pt-5 mx-8">
          <span class="title-box">DESCRIPTION BUSINESS</span>
        </div>
        <div class="flex mx-8 pt-9">
          <!-- <span>Title</span> -->
          <vs-input
            label="Title"
            class="input-bluexpress-component"
            v-model="section_business.title"
            placeholder="Empresas que confían en nosotros"
          />
        </div>
        <div class="flex items-center justify-end w-auto pt-5 mx-8">
          <vs-button
            :disabled="!businessValidation"
            @click="editSection(section_business)"
          >
            Guardar
          </vs-button>
        </div>
      </div>
    </div>
    <!-- END Business Uploader --->
  </div>
</template>

<script>
import Square from "@/components/UploaderSquare/Index.vue";
import Pdf from "@/components/PdfUploader/Index.vue";
export default {
  components: {
    Square,
    Pdf,
  },
  data: () => ({
    intro_section: {},
    section_1: {},
    section_2: {},
    section_3: {},
    duo_1: {},
    //duo_2: {},
    section_rrss: {},
    section_business: {},
  }),
  computed: {
    introValidation() {
      if (
        this.intro_section.title !== "" &&
        this.intro_section.subtitle !== "" &&
        this.intro_section.button_name !== "" &&
        this.intro_section.pdf_url !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    section1Validation() {
      if (
        this.section_1.title !== "" &&
        this.section_1.subtitle !== "" &&
        this.section_1.button_name !== "" &&
        this.section_1.pdf_url !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    section2Validation() {
      if (
        this.section_2.title !== "" &&
        this.section_2.subtitle !== "" &&
        this.section_2.button_name !== "" &&
        this.section_2.pdf_url !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    section3Validation() {
      if (
        this.section_3.title !== "" &&
        this.section_3.subtitle !== "" &&
        this.section_3.button_name !== "" &&
        this.section_3.pdf_url !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    duo1Validation() {
      if (
        this.duo_1.title !== "" &&
        this.duo_1.subtitle !== "" &&
        this.duo_1.button_name !== "" &&
        this.duo_1.pdf_url !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    rrssValidation() {
      if (
        this.section_rrss.title !== "" &&
        this.section_rrss.subtitle !== "" &&
        this.section_rrss.instagram_url !== "" &&
        this.section_rrss.facebook_url !== "" &&
        this.section_rrss.whatsapp_url !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    businessValidation() {
      if (this.section_business.title !== "") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getSections();
  },
  methods: {
    getSections() {
      const loading = this.$vs.loading({
        color: "#0276FF",
      });
      this.$store.dispatch("LandingModule/getSections").then((response) => {
        this.sections = response;

        for (const element of this.sections) {
          switch (element.type_banner) {
            case "intro_section":
              this.intro_section = element;
              break;
            case "section_1":
              this.section_1 = element;
              break;
            case "section_2":
              this.section_2 = element;
              break;
            case "section_3":
              this.section_3 = element;
              break;
            case "duo_1":
              this.duo_1 = element;
              break;
            // case "duo_2":
            //   this.duo_2 = element;
            //   break;
            case "section_rrss":
              this.section_rrss = element;
              break;
            case "section_business":
              this.section_business = element;
              break;

            default:
              this.section_1 = element;
              break;
          }
        }

        loading.close();
      });
    },
    editSection(element) {
      this.$store
        .dispatch("LandingModule/updateSections", element)
        .then(() => {
          this.getSections();
        })
        .catch((error) => {
          this.$notify({
            group: "foo",
            title: "Error",
            text: error,
            type: "warn",
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
.center-flex {
  justify-content: center;
}

.rectangle {
  width: 70%;
  height: 45vh;
  margin-right: 8px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.vs-input {
  width: 100%;
}

.vs-input-parent {
  width: 100%;
  margin: 0px 10px;
}

@media (max-height: 899px) {
  .rectangle {
    height: 55vh;
  }
}
</style>
